.App {
  text-align: center;
  background: #e0e0e0;
}

.App-link {
  color: #61dafb;
}

body, html {
  height: 100%;
  overflow-x: hidden;
}

@media (max-width: 768px) {
  /* CSS in here for mobile only */
  .hero {
    z-index: 5;
    height: 250px;
  }

  .hero-image {
    border: 4px solid #993366;
    border-radius: 10px;
    max-width: 100%;
    height: 150px;
  }

  /* The logo image */
  .logo-image {
    position: relative;
    display: flex;
    max-width: 20%;
    height: auto;
    z-index: 10;
    top: 40px;
  }

  .Home {
    max-width: 100%;
    height: 100px;
    margin:auto;
    background:white;
    display: flex;
    flex-direction: row;
  }

  .Welcome {
    max-width: 100%;
    margin:auto;
    background:white;
    display: flex;
    flex-direction: row;
  }

  .Welcome-text {
    max-width: 100%;
    margin:auto;
    background:white;
  }

  .OutterForm {
    max-width: 100%;
    margin: auto;
    background:white;
  }

  .InnerForm {
    max-width: 100%;
    margin: auto;
    background:white;
    display: flex;
    flex-direction: row;
  }

  .Login {
    max-width: 100%;
    margin:auto;
    background:white;
    display: flex;
    flex-direction: row;
    text-align: center;
  }

  .Huts {
    max-width: 100%;
    margin:auto;
    background:white;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .RightButtons {
    max-width: 100%;
    padding-left: 5px;
    margin:0;
    background:white;
    display: flex;
    flex-direction: column;
    float: left;
  }

  .NavBar {
    max-width: 100%;
    padding-left: 5px;
    margin:0;
    background:white;
    display: flex;
    flex-direction: column;
    float: left;
  }

  .Sponsors {
    max-width: 100%;
    margin:auto;
    background:#e9edeb;
  }

  .copyright {
    max-width: 100%;
    margin: auto;
    text-align:'left';
    padding-left: 1%;
  }

  .memberCard {
    max-width: 100%;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    float: center;
  }
}

@media (min-width: 769px) {
  /* CSS in here for desktop only */
  /* The hero image */
  .hero {
    z-index: 5;
    height: 500px;
  }

  .hero-image {
    border: 4px solid #993366;
    border-radius: 10px;
    width: 958px;
  }

  /* The logo image */
  .logo-image {
    position: relative;
    top: 70px;
    left: -400px;
    z-index: 10;
    width: 150px;
    height: 150px;
  }

  .Home {
    width: 958px;
    height: 150px;
    margin:auto;
    background:white;
  }

  .Welcome {
    width: 958px;
    margin:auto;
    background:white;
    display: flex;
    flex-direction: row;
  }

  .Welcome-text {
    width: 659px;
    margin:auto;
    background:white;
  }

  .OutterForm {
    width: 659px;
    margin: auto;
    background:white;
  }

  .InnerForm {
    width: 659px;
    margin: auto;
    background:white;
    display: flex;
    flex-direction: row;
  }

  .Login {
    width: 958px;
    margin:auto;
    background:white;
    display: flex;
    flex-direction: row;
    text-align: center;
  }

  .Huts {
    width: 958px;
    margin:auto;
    background:white;
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .RightButtons {
    width: 260px;
    padding-left: 5px;
    margin:0;
    background:white;
    display: flex;
    flex-direction: column;
    float: left;
  }

  .NavBar {
    width: 260px;
    padding-left: 5px;
    margin:0;
    background:white;
    display: flex;
    flex-direction: column;
    float: left;
  }

  .Sponsors {
    width: 958px;
    margin:auto;
    background:#e9edeb;
  }

  .copyright {
    width: 958px;
    margin: auto;
    text-align:'left';
    padding-left: 1%;
  }

  .memberCard {
    width: 958px;
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    float: center;
  }
}